<template>
	<div class="quotation" v-title="'装修报价——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
       
        <div class="content f-y-s-c">
            <div @click="openDialog('price')" class="box1">
                <img src="../image/price/box1_pc.jpg" alt="" class="img1">
            </div>
            <div @click="openDialog('price')" class="box-mob">
                <img
                    class="bran"
                    src="../image/price/box1_mob.jpg"
                    alt=""
                />
		    </div>



            <div class="box2">
                <div class="box2-left f-y-c-c">
                    <span class="text1">装修报价计算器</span>
                    <span class="text2">今天已有 213 位业主获取了装修预算</span>
                    <div class="info f-y-s-c">
                        <el-form
                            :inline="true" 
                            ref="ruleFormRef"
                            :model="visitorInfo"
                            :rules="rules"
                            class="cus-form"
                        >

                        <el-form-item   >
                            <div  class="list-item ">
                                <div class="left-label f-x-c-c">
                                    所在城市：
                                </div>
                                <el-cascader
                                    placeholder=""
                                    class="input city"
                                    size="default"
                                    :options="cityStoreList"
                                    v-model="city"
                                >
                                </el-cascader>
                            </div>
                        </el-form-item>
                        <el-form-item  prop="mobile">
                            <div  class="list-item ">
                                <div class="left-label f-x-c-c">
                                    您的电话：
                                </div>
                                <el-input
                                    class="input phone"
                                    v-model="visitorInfo.mobile"
                                    inputmode="numeric"
                                >
                                    <div slot="prefix" class="req">*</div>
                                </el-input>
                            </div>
                        </el-form-item>
                        <el-form-item  prop="houseArea">
                            <div  class="list-item ">
                                <div class="left-label f-x-c-c">
                                    房屋面积：
                                </div>
                                <el-input
                                    class="input"
                                    v-model="visitorInfo.houseArea"
                                    inputmode="numeric"
                                >
                                    <div slot="suffix" class="m2">m²</div>
                                </el-input>
                            </div>
                        </el-form-item>
                        <el-form-item  >
                            <div  class="list-item ">
                                <div class="left-label f-x-c-c">
                                    房屋户型：
                                </div>
                                <el-select v-model="visitorInfo.houseType" placeholder=""  class="input">
                                    <el-option
                                        
                                        v-for="item in houStyleDict"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </el-form>

                        <!-- <div  class="list-item ">
                            <div class="left-label f-x-c-c">
                                所在城市：
                            </div>
                            <el-cascader
                                placeholder=""
                                class="input city"
                                size="default"
                                :options="cityStoreList"
                                v-model="city"
                            >
                            </el-cascader>
                        </div>
                        <div  class="list-item ">
                            <div class="left-label f-x-c-c">
                                您的电话：
                            </div>
                            <el-input
                                class="input phone"
                                v-model="visitorInfo.mobile"
                                inputmode="numeric"
                            >
                                <div slot="prefix" class="req">*</div>
                            </el-input>
                        </div>

                        <div  class="list-item ">
                            <div class="left-label f-x-c-c">
                                房屋面积：
                            </div>
                            <el-input
                                class="input"
                                v-model="visitorInfo.houseArea"
                                inputmode="numeric"
                            >
                                <div slot="suffix" class="m2">m²</div>
                            </el-input>
                        </div>
                        <div  class="list-item ">
                            <div class="left-label f-x-c-c">
                                房屋户型：
                            </div>
                            <el-select v-model="visitorInfo.houseType" placeholder=""  class="input">
                                <el-option
                                    
                                    v-for="item in houStyleDict"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div> -->
                    </div>
                    <span class="text3">我们向您保证，我们将对您的信息严格保密</span>
                </div>
                <div class="box2-right f-y-c-c">
                    <div class="right-title f-x-c-c">
                        <span class="text2">你家装修预算 </span>
                        <span class="text5"> &nbsp;{{price}}&nbsp;</span>
                        <span class="text2">元</span>
                    </div>
                    
                    <div class="list f-x-b-c">
                        <div class="list-item f-y-c-c">
                            <div class="icon f-x-c-c">
                                <img class="img1" src="../image/price/box2_icon1.png" alt="">
                            </div>
                            <span class="text3">免费</span>
                            <span class="text4">报价规范</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <div class="icon f-x-c-c">
                                <img class="img1" src="../image/price/box2_icon2.png" alt="">
                            </div>
                            <span class="text3">严选</span>
                            <span class="text4">环保建材</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <div class="icon f-x-c-c">
                                <img class="img1" src="../image/price/box2_icon3.png" alt="">
                            </div>
                            <span class="text3">F2C直采</span>
                            <span class="text4">直省预算</span>
                        </div>
                    </div>
                    <p class="text4">本报价按照房屋面积估算，最终价格以量房实测为准，仅适用普通住宅，不适用别墅、复式等住宅。</p>
                </div>
                <div class="line"></div>
                <div @click="submitForm" class="btn f-x-c-c">
                    <div class="q3 f-y-c-c">
                        <span>开始</span>
                        <span>报价</span>
                    </div>
                    <div class="q2 f-x-c-c">
                    </div>
                </div>
            </div>
            <div class="box3 f-y-c-c">
                <div class="header f-y-c-c">
                    <span class="text1">为什么要提前获取装修预算</span>
                    <span class="text2">从设计、施工、产品、售后等全流程做综合询价</span>
                </div>
                <div class="list">
                    <div class="list-item item1 f-y-s-s">
                        <div class="mask"></div>
                        <span class="text3">装修品质对比</span>
                        <div class="cus-line"></div>
                        <p class="text4">了解报价后，可以从设计、施工、产品、售后等全流程做综合询价比价考评。</p>
                        <div class="index f-x-s-e">
                            01
                        </div>
                    </div>
                    <div class="list-item  item2 f-y-s-s">
                        <div class="mask"></div>
                        <span class="text3">快速装修入门</span>
                        <div class="cus-line"></div>
                        <p class="text4">装修报价是综合风格、面积等得出帮您清晰梳理家装流程,快速装修入门。</p>
                        <div class="index f-x-s-e">
                            02
                        </div>
                    </div>
                    <div class="list-item item3 f-y-s-s">
                        <div class="mask"></div>
                        <span class="text3">提前费用规划</span>
                        <div class="cus-line"></div>
                        <p class="text4">了解装修报价后，对装修资金有合理的准备，并提前做好充足的费用规划。</p>
                        <div class="index f-x-s-e">
                            03
                        </div>
                    </div>
                    <div class="list-item item4 f-y-s-s">
                        <div class="mask"></div>
                        <span class="text3">装修提上日程</span>
                        <div class="cus-line"></div>
                        <p class="text4">很多人怕麻烦、担心自己不懂装修，
总是一拖再拖，提前报价，将装修提上日程，早日入住新家。</p>
                        <div class="index f-x-s-e">
                            04
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="list-item item5 f-y-s-s">
                        <div class="mask"></div>
                        <span class="text3">节省装修时间</span>
                        <div class="cus-line"></div>
                        <p class="text4">成功获取报价的客户，系统均做好完整备案，随时准备进行装修接待，节省装修时间。</p>
                        <div class="index f-x-s-e">
                            05
                        </div>
                    </div>
                </div>
            </div>
             
            <div class="box4 f-y-s-c">
                <div class="header f-y-c-c">
                    <span class="text1">精准测算·透明家装</span>
                    <span class="text2">装修费用清单明细，让您对支出心中有数</span>
                </div>
                <div class="box4-content f-y-s-c">
                    <div class="list f-x-b-c">
                        <div class="list-item f-y-s-c">
                            <img class="img2" src="../image/price/box4_bg2.jpg" alt="">
                            <span class="text3">人工费透明</span>
                            <p class="text4">统一培训上岗，施工标准规范，自有化工人</p>
                        </div>
                        <div class="list-item f-y-s-c">
                            <img class="img2" src="../image/price/box4_bg3.jpg" alt="">
                            <span class="text3">材料费透明</span>
                            <p class="text4">国际大牌环保材料，价格透明优中选优</p>
                        </div>
                        <div class="list-item f-y-s-c">
                            <img class="img2" src="../image/price/box4_bg4.jpg" alt="">
                            <span class="text3">家具软装透明</span>
                            <p class="text4">大型家装体验馆，多种款式产品，品类齐全随心选</p>
                        </div>
                    </div>
                    <div @click="openDialog('price')" class="btn f-x-c-c">
                        免费获取装修报价
                    </div>
                </div>
            </div>
            <div class="box5 f-y-s-c">
                <div class="header f-y-c-c">
                    <span class="text1">专注高端家装设计团队</span>
                    <span class="text2">专属设计团队 ，做懂您所想的生活方式设计者</span>
                </div>
                <div class="box-content">
                    <div
                        v-for="(item,index) in desList.slice(0,5)"
                        :key="index" 
                        @click="navToDesignDet(item.id)"
                        class="list-item f-y-e-c"
                        :class="'item'+index"
                        >
                        <!-- :style="`background: url(${item.headerImgUrl})`" -->

                        <img class="list-img" :src="item.headerImgUrl" alt="">
                        <div class="info">
                            <div class="mask"></div>
                            <div class="head">
                                <span class="text4">{{item.name}}</span>
                                <span class="text5">{{ item.ranks && formartData('ranks',item.ranks)}}</span>
                            </div>
                            <span v-if="index==0" class="text1">从业经验：{{item.workingLife}}年</span>
                            <div v-if="index==0" class="info-box f-x-s-s">
                                <span class="text2">擅长风格：</span>
                                <p class="text3 eps2">{{item.designStyle}}</p>
                            </div>
                            <div @click.stop="openDialog('design')" v-if="index==0" class="btn f-x-c-c">
                                找TA设计
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row2">
                    <div
                        v-for="(item,index) in desList.slice(5,10)"
                        :key="index" 
                        @click="navToDesignDet(item.id)"
                        class="des-item">
                        <img class="img" :src="item.headerImgUrl" alt="">
                    </div>
                </div>
            </div>
            
            <div class="box6 f-y-c-c">
                <div class="header f-y-c-c">
                    <span class="text1">全球品牌环保建材聚势造精居</span>
                    <span class="text2">主材+辅材+家具+电器高颜值品质高性价比一站购齐</span>
                </div>
                <div class="box6-content f-y-c-c">
                    <div class="tab-list f-x-b-c">
                        <div
                            v-for="(item,index) in tabList"
                            :key="index"
                             class="list-item f-x-c-c"
                             :class="curTab==index? 'tab-active':''"
                             @click="cahngeTabs(index)"
                             >
                            <img class="img3" :src="item.icon" alt="" >
                        </div>
                    </div>
                    <div class="banner">
                        <img class="img4" :src="tabList[curTab].url" alt="">
                        <div class="info f-y-s-s">
                            <span class="text3">{{tabList[curTab].title}}</span>
                            <span class="text4">{{tabList[curTab].form}}</span>
                            <p class="text5">
                                {{tabList[curTab].desc}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
		</div>
        <Flow></Flow>
		<!-- 底部 -->
		<Footer></Footer>
        <Floating></Floating>
        <FloatingMob></FloatingMob>

        <DialogForm ref="refDialogForm" adUnitCode="MObN"></DialogForm>
        <MessageForm ref="refMessage" :list="errList"></MessageForm>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Flow from '../components/Flow.vue'
import AboutNav from '../components/AboutNav.vue'
import { getNewsData } from '@/api/news.js'
import { postmobile } from '@/api/views/name.js'
import { useDesDict,useHousetypeDict } from '@/utils/frequent.js'
import {useCityInfo} from '@/utils/frequent.js'

export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
        Flow
	},
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback('请输入正确的电话号码');
            }else{
                callback();
            }
            
        };
		return {
			city:[],
            curTab:0,
			visitorInfo: {
				houseArea: '',
                houseType:'',
				mobile: '', //电话
				name: '客户',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: 'MObN', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
			},
            rules:{
                houseArea: [
                    { required: true, message: ' ', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            errList:[],
            houStyleDict:[],
            desList:[],
            tabList:[
                {
                    value:1,
                    icon:require('../image/price/box6_icon1.jpg'),
                    url:require('../image/price/box6_img1.jpg'),
                    title:'菲林格尔 ',
                    form:'始创于1921年·德国',
                    desc:'菲林格尔成立于德国巴登符腾堡州。九十余年来，菲林格尔逐渐发展为欧洲最大的旅行车内家具制造商，是梅赛德斯·奔驰等著名汽车公司车内木制品的供货商和著名家具中间产品供货商。'
                },
                {
                    value:2,
                    icon:require('../image/price/box6_icon2.jpg'),
                    url:require('../image/price/box6_img2.jpg'),
                    title:'阿尔贝娜',
                    form:'Alpina品牌成立于1909年',
                    desc:'专注于消费者涂料市场，品牌源自DAW SE第一代创始人在阿尔卑斯山度假时，被雪山白色的美景吸引，萌发了要研制出一款像雪山一样白的装饰涂料。'
                },
                {
                    value:3,
                    icon:require('../image/price/box6_icon3.jpg'),
                    url:require('../image/price/box6_img3.jpg'),
                    title:'索菲亚',
                    form:'SOGAL源自法国1981年',
                    desc:'索菲亚一直专注柜类定制家具，产品包含衣柜、书柜、电视柜等定制家具。索菲亚板材环保超越欧标，板材曾获美国环保署NAF环保认证和通过瑞士SGS机构严苛测定。'
                },
                {
                    value:4,
                    icon:require('../image/price/box6_icon4.jpg'),
                    url:require('../image/price/box6_img4.jpg'),
                    title:'大金',
                    form:'自1924年成立以来',
                    desc:'始终致力于探索健康、舒适空气的奥秘。作为一家集空调、冷媒、压缩机的研发、生产、销售、售后服务位一体的世界知名企业。'
                },
                {
                    value:5,
                    icon:require('../image/price/box6_icon5.jpg'),
                    url:require('../image/price/box6_img5.jpg'),
                    title:'慕思',
                    form:'DeRUCCI 品牌创立于2004年',
                    desc:'致力于人体健康睡眠研究，专业从事整套健康睡眠系统的研发、生产和销售，在业内创新打造了“量身定制个人专属的健康睡眠系统”。'
                },
                {
                    value:6,
                    icon:require('../image/price/box6_icon6.jpg'),
                    url:require('../image/price/box6_img6.jpg'),
                    title:'卫水宝',
                    form:'世界领先的PP-R 管道产品制造商之一',
                    desc:'VESBO(卫水宝)公司是一家在热塑管工业领域一直处于领先地位的制造商。VESBO不仅为室内给排水提供了解决的方法，在垂直，加热,污水处理等领域中更是佼佼者。'
                },

            ],
            cityStoreList:[],
            price:112585,
            priceInterval:null,
            timer:''
            
		}
	},
    async mounted(){
        // this.getNewsList()
        this.houStyleDict = await useHousetypeDict()
        if(this.curCityInfo.value=='00'){
            this.desList = (await useDesDict()).filter(item=>item.isHeadOffice ==1)
        }else{
            this.desList = (await useDesDict()).filter(item=>item.areaCode ==this.curCityInfo.value)
        }
        
        this.cityStoreList = await useCityInfo()
        if(this.curCityInfo.value!='00') this.setCity()
        this.startPriceJump()
        this.setAccessInfo()
    },
	methods: {
        setAccessInfo() {
			this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
			this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
			this.visitorInfo.reqUrl = window.location.href

            this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
            this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
            this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
            this.visitorInfo.projectName =  this.$Cookie.get('projectName')
            this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
		},
        startPriceJump() {
            this.priceInterval = setInterval(() => {
                this.price = Math.floor(Math.random() * (300000 - 109565 + 1)) + 109565;
            }, 1000); // 每秒更新一次价格
        },
        cahngeTabs(index){
            this.curTab = index
        },
        navToDesignDet(id){
            this.$router.push({
                path:'/design/detail',
                query:{
                    id
                }
            })
        },
        openDialog(type){
            this.$refs.refDialogForm.showDialog(type)
        },
        changeCurrent(data){
            this.current = data
            this.getNewsList()
        },
        prePage(){
            if(this.current!=1){
                this.current -=1
                this.getNewsList()
            }
            
        },
        nextPage(){
            if(this.current<((this.total/this.size).toFixed(0))){
                this.current +=1
                this.getNewsList()
            }
        },
        async getNewsList(){
            try{
                const params = {
                    size:this.size,
                    current:this.current,
                }
                const res = await getNewsData(params)
                if(res.code==0){
                    this.newsList = res.data.records
                    this.total = res.data.total
                }
            }catch(err){
                console.log(err);
            }
        },
        async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    console.log("aa",obj);
                    if(valid){
                        if(this.city.length){
                            this.visitorInfo.cityCode = this.city[0]
                            this.visitorInfo.salesRoomNo = this.city[1]
                        }

                        const res = await postmobile(this.visitorInfo)
                        this.$refs.refMessage.showDialog('sucess')
                        this.city=[]
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
    },
    beforeDestroy() {
        if (this.priceInterval) {
            clearInterval(this.priceInterval);
        }
        clearTimeout(this.timer)
    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .quotation {
        overflow-x: hidden;
        .content{
            margin-top:43px;
            .box1 {
                display: none;
            }
            .box-mob{
                width: 100%;
                height: 200px;
                .bran {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .box2{
                position: relative;
                z-index: 9;
                width: 95%;
                // height: 680px;
                background: #FFFFFF;
                box-shadow: 0px 10px 15px 0px rgba(4,0,0,0.1);
                border-radius: 15px;
                margin-top: -50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-flow: column;
                .box2-left{
                    width: 100%;
                    // height: 300px;
                    padding: 20px 30px;
                    box-sizing: border-box;
                    .text1{
                        
                        font-weight: bold;
                        font-size: 20px;
                        color: #18062F;
                    }
                    .text2{
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                        margin-top: 5px;
                    }
                    .text3{
                        
                        font-weight: 300;
                        font-size: 10px;
                        color: #B1B1B1;
                    }
                    
                    .info{
                        margin-top: 10px;
                        width: 100%;
                        .cus-form{
                            width: 100%;
                            .el-form-item{
                                width: 100%;
                                /deep/.el-form-item__content{
                                    width: 100%;
                                }
                                
                                margin-right: initial !important;
                                margin-bottom: initial !important;
                            }
                        }
                        .list-item{
                            width: 100%;
                            position: relative;
                            .left-label{
                                position: absolute;
                                z-index: 9;
                                height: 30px;
                                width: 80px;
                                padding-left: 5px ;
                                box-sizing: border-box;
                                left: 5px;
                                top: 5px;
                                background: rgb(255, 255, 255);
                                
                                font-weight: 300;
                                font-size: 15px ;
                                color: #444444 ;
                            }
                            .phone {
                                /deep/.el-input__inner {
                                    padding-left: 15px !important;
                                }
                            }
                            .input {
                                width: 100%;
                                margin-bottom: 10px;
                                .req {
                                    color: #e30c0c;
                                    height: 40px;
                                    line-height: 40px;
                                }
                                /deep/.el-input__inner {
                                    width: 100%;
                                    height: 40px;
                                    padding-left: 85px !important;
                                    
                                    font-weight: 300;
                                    font-size: 15px !important;
                                    color: #444444 !important;
                                    line-height: 40px !important;
                                }
                                /deep/.el-input__suffix {
                                    right: 10px;
                                    .m2 {
                                        
                                        font-weight: 300;
                                        color: #666666;
                                        line-height: 15px;
                                        height: 40px;
                                        line-height: 40px;
                                        font-size: 15px;
                                    }
                                }
                                /deep/.el-icon-arrow-down {
                                    font-size: 15px !important;
                                }
                            }
                            .el-cascader {
                                /deep/.el-input__suffix {
                                    right: 10px !important;
                                }
                            }
                        }

                        // .input{
                        //     width: 100%;
                        //     margin-bottom: 10px;
                        //     .req{
                        //         color: #E30C0C;
                        //         height: 35px;
                        //         line-height: 35px;
                        //     }
                        //     /deep/ .el-input{
                        //         width: 100%;
                        //         height: 35px;
                                
                        //         .el-input__inner{
                        //             width: 100%;
                        //             height: 35px;
                        //         }
                        //     }
                        //     /deep/ .el-input__inner{
                        //         width: 100%;
                        //         height: 35px;
                        //         font-size: 12px !important;
                        //     }
                            
                            
                        //     /deep/.el-input__suffix{
                        //         right: 15px;
                        //         .m2{
                        //             height: 35px;
                        //             line-height: 35px;
                        //             font-size: 12px;
                        //         }
                        //     }
                        //     /deep/.el-icon-arrow-down{
                        //         font-size: 12px !important;

                        //     }
                        // }
                        // .phone{
                        //     /deep/.el-input__inner{
                        //      padding-left: 15px !important;   
                        //     }
                        // }
                        // .el-cascader{
                        //     /deep/.el-input__suffix{
                        //         right: 15px !important;
                        //     }
                        // }
                        // .el-select{
                        //     width: 100%;
                        //     margin-bottom: 5px;
                        //     /deep/.el-input__inner{
                        //         height: 35px;
                        //         font-size: 12px !important;
                        //         // padding-left: 180px;
                        //     }
                        //     /deep/.el-input__suffix{
                        //         right: 15px !important;
                        //         line-height: 35px !important;
                        //     }
                        //     /deep/.el-icon-arrow-up{
                        //         font-size: 12px !important;
                        //     }
                        //     /deep/ .el-input__prefix{
                        //         left: 15px;
                        //         line-height: 35px;
                        //     }
                        //     // .prefix{
                        //     //     font-size: 30px !important;
                        //     // }
                        // }
                        
                    }
                }
                .box2-right{
                    width: 100%;
                    padding: 20px 10px;
                    box-sizing: border-box;
                    margin-top: 80px;
                    // height: 100%;
                    .right-title{
                        .text2{
                            
                            font-weight: bold;
                            font-size: 20px;
                            color: #18062F;
                        }
                        .text5{
                            
                            font-weight: bold;
                            font-size: 20px;
                            color: #753CBE;
                        }
                    }
                    .list{
                        margin-top: 20px;
                        width: 100%;
                        .list-item{
                            .icon{
                                width: 80px;
                                height: 80px;
                                border-radius: 10px;
                                border: 1px solid #DFDDE1;
                                .img1{
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                            .text3{
                                margin-top: 5px;
                                
                                font-weight: bold;
                                font-size: 13px;
                                color: #18062F;
                            }
                            .text4{
                                margin-top: 1px;
                                
                                font-weight: bold;
                                font-size: 13px;
                                color: #18062F;
                            }
                        }
                    }
                    .text4{
                        
                        font-weight: 400;
                        font-size: 10px;
                        color: #18062F;
                        line-height: 18px;
                        margin-top: 15px;
                        text-align: center;
                    }
                    
                }
                .line{
                    position: absolute;
                    left: 0;
                    top: 350px;
                    width: 100%;
                    height: 1px;
                    background: #E5E5E5;
                }
                

                

                .btn{
                    cursor: pointer;
                    position: absolute;
                    left: calc((100% - 221px)/2);
                    top: 310px;
                    width: 221px;
                    height: 65px;
                    background: #FFFFFF;
                    border-radius: 6px;
                    border: 1px solid #DFDDE1;
                    .q3{
                        z-index: 9;
                        width: 200px;
                        height: 45px;
                        background: #FD8B2F;
                        justify-content: center;
                        flex-flow: row;
                        // border-radius: 50%;
                        border-radius: 6px;
                        span{
                            
                            font-weight: bold;
                            font-size: 21px;
                            color: #FFFFFF;

                        }
                    }
                    .q2 {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 200px;
                        height: 45px;
                        background-color: rgba(253, 139, 47, 0.4);
                        // border-radius: 50%;
                        border-radius: 6px;
                        transform: translate(-50%, -50%) scale(1);
                        opacity: 1;
                        pointer-events: none;
                        animation: ripple-animation 0.9s infinite ;
                    }
                    @keyframes ripple-animation {
                        0% {
                            transform: translate(-50%, -50%) scale(1);
                            opacity: 1;
                        }
                        // 20%{
                        //     transform: translate(-50%, -50%) scale(1.05);
                        //     opacity: 0.8;
                        // }
                        // 40%{
                        //     transform: translate(-50%, -50%) scale(1.15);
                        //     opacity: 0.6;
                        // }
                        // 60%{
                        //     transform: translate(-50%, -50%) scale(1.2);
                        //     opacity: 0.4;
                        // }

                        // 80%{
                        //     transform: translate(-50%, -50%) scale(1.25);
                        //     opacity: 0.2;
                        // }
                        
                        100% {
                            transform: translate(-50%, -50%) scale(1.1,1.5);
                            opacity: 0;
                        }
                    }
                }
            }
            .box3{
                padding: 0 10px;
                background: #fff;
                margin-top: 30px;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 24px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 5px;
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                    }
                }
                .list,.bottom{
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    .item1{
                        background: url(../image/price/box3_bg1.jpg);
                    }
                    .item2{
                        background: url(../image/price/box3_bg2.jpg);
                    }
                    .item3{
                        background: url(../image/price/box3_bg3.jpg);
                    }
                    .item4{
                        background: url(../image/price/box3_bg4.jpg);
                    }
                    
                    .list-item{
                        width: 170px;
                        height: 100px;
                        border-radius: 8px;
                        overflow: hidden;
                        position: relative;
                        
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        padding: 10px 5px;
                        box-sizing: border-box;
                        margin-bottom: 10px;
                        .mask{
                            border-radius: 8px 15px 8px 8px;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background: rgba(117, 60, 190, 0.9);
                        }
                        .text3{
                            z-index: 9;
                            
                            font-weight: bold;
                            font-size: 14px;
                            color: #FFFFFF;
                        }
                        .cus-line{
                            z-index: 9;
                            width: 30px;
                            height: 1px;
                            background: #FFFFFF;
                            margin-top: 5px;
                        }
                        .text4{
                            z-index: 9;
                            
                            font-weight: 400;
                            font-size: 10px;
                            color: #FFFFFF;
                            line-height: 16px; 
                            margin-top: 5px;
                        }
                        .index{
                            position: absolute;
                            right: -35px;
                            top: -35px;
                            background: #F8F8F9;
                            width: 75px;
                            height: 75px;
                            border-radius: 50%;
                            font-family: DIN;
                            font-weight: bold;
                            font-size: 20px;
                            color: #753CBE;
                            line-height: 30px;
                            padding-left:15px ;
                            padding-bottom: 10px;
                            box-sizing: border-box;
                        }
                    }
                    
                }
                .bottom{
                    margin-top: 0px;
                    width: 100%;
                    height: 150px;
                    border-radius: 8px;
                    padding: 25px 10px;
                    background-repeat: no-repeat;
                    box-sizing: border-box;
                    background: url(../image/price/box3_bg5.jpg);
                    background-size: cover;
                    .list-item{
                    }
                }
            }
            .box4{
                background: #fff;
                width: 100%;
                margin-top: 30px;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 24px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 5px;
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                    }
                }
                .box4-content{
                    margin-top: 20px;
                    // height: 960px;
                    width: 100%;
                    background: url(../image/price/box4_bg1.jpg);
                    background-size:cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    padding: 0 10px 20px 10px;
                    box-sizing: border-box;
                    .list{
                        width: 100%;
                        .list-item{

                            width: 115px;
                            height: 160px;
                            background: #FFFFFF;
                            border-radius: 5px;
                            overflow: hidden;
                            .img2{
                                height: 80px;
                                width: 100%;
                                // object-fit: cover;
                            }
                            .text3{
                                
                                font-weight: bold;
                                font-size: 12px;
                                color: #120523;
                                margin-top: 8px;
                                padding: 0 10px;

                            }
                            .text4{
                                margin-top: 3px;
                                
                                font-weight: 400;
                                font-size: 10px;
                                color: #444444;
                                line-height: 16px;
                                padding: 0 5px;
                                text-align: center;
                            }
                        }
                    }
                    .btn{
                        width: 130px;
                        height: 35px;
                        background: #753CBE;
                        border-radius: 40px;
                        
                        font-weight: bold;
                        font-size: 13px;
                        color: #FFFFFF;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }
            }
            .box5{
                background: #fff;
                width: 100%;
                margin-top: 30px;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 24px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 5px;
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                    }
                }
                .box-content{
                    cursor: pointer;
                    display: grid;
                    width: 100%;
                    margin-top: 15px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    // grid-template-columns: 50% 25% 25%;
                    grid-gap: 5px;
                    // grid-template-rows: 200px 100px;
                    // grid-template-columns: repeat(3, 1fr);
                    grid-template-columns: 2fr 1fr 1fr;
                    grid-auto-flow: row;
                    .item0{
                        grid-row: 1 / span 2;
                        // width: 170px !important;
                        width: 100% !important;
                        height: 220px !important;
                        .info{
                            align-content: flex-start;
                            height: 80px !important;
                            padding:5px !important;
                        }
                        .head{
                            flex-flow: row !important;
                            display: flex;
                            align-items: flex-end !important;
                            justify-content: flex-start !important;
                            .text4{
                                line-height: 13px !important;
                                font-size: 13px !important;
                            }
                        }
                    }
                    .item3{
                        width: 65px;
                    }
                    .list-item:hover{
                        .list-img{
                            transform: scale(1.2)
                        }
                    }
                    .list-item{
                        position: relative;
                        width: 100%;
                        height: 107px;
                        overflow: hidden;
                        .list-img{
                            transition: all .6s;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            object-fit: cover;
                        }
                       .info{
                            height: 30px;
                            position: relative;
                            padding:3px ;
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            align-content: center;
                            width: 100%;
                            box-sizing: border-box;
                            .mask{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: rgba(0,0,0,0.5);
                            }
                            .head{
                                flex-flow: column;
                                z-index: 9;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .text4{
                                    
                                    font-weight: 600;
                                    color: #FFFFFF;
                                    margin-right: 5px;
                                    font-size: 13px;
                                    line-height: 13px;
                                }
                                .text5{
                                    margin-top: 3px;
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #FFFFFF;
                                    line-height: 10px;
                                }
                            }
                            .text1{
                                z-index: 9;
                                margin-top: 5px;
                                
                                font-weight: 400;
                                font-size: 10px;
                                color: #FFFFFF;
                            }
                            .info-box{
                                z-index: 9;
                                margin-top: 5px;
                                .text2{
                                    width: 50px;
                                    min-width: 50px;
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #FFFFFF;
                                }
                                .text3{
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #FFFFFF;
                                }
                            }
                            .btn{
                                z-index: 9;
                                // cursor: pointer;
                                position: absolute;
                                top: 10px;
                                right: 0;
                                width: 60px;
                                height: 20px;
                                background: #753CBE;
                                border-radius: 16px 0px 0px 16px;
                                
                                font-weight: 400;
                                font-size: 10px;
                                color: #FFFFFF;
                            }
                       }
                    }
                }
                .row2{
                    width: 100%;
                    margin-top: 5px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    display: grid;
                    justify-content: flex-start;
                    grid-template-columns: repeat(5, 1fr);
                    grid-gap: 5px;
                    .des-item{
                        cursor: pointer;
                        width: 100%;
                        height: 90px;
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .box6{
                width: 100%;
                margin-top: 30px;
                background: #fff;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 24px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 5px;
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                    }
                }
                .box6-content{
                    width: 100%;
                    margin-top: 15px;
                    .tab-list{
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
                        .list-item{
                            width: 50px;
                            height: 50px;
                            background: #FFFFFF;
                            box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
                            border-radius: 50%;
                            overflow: hidden;
                            cursor: pointer;
                            box-sizing: border-box;
                            // border: 3px solid #7840BF;
                            .img3{
                                width: 40px;
                                height: 35px;
                            }
                        }
                        .tab-active{
                            border: 1px solid #7840BF;
                        }
                    }
                    .banner{
                        margin-top: 18px;
                        width: 100%;
                        height: 300px;
                        position: relative;
                        .img4{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        .info{
                            position: absolute;
                            top:50px;
                            right: 0;
                            width: 250px;
                            height: 200px;
                            background: #FFFFFF;
                            border-radius:9px 0 0 9px;
                            opacity: 0.98;
                            padding: 30px 15px 15px 15px;
                            box-sizing: border-box;
                            .text3{
                                
                                font-weight: bold;
                                font-size: 20px;
                                color: #120523;
                                line-height: 20px;
                            }
                            .text4{
                                
                                font-weight: bold;
                                font-size: 16px;
                                color: #120523;
                                line-height: 18px;
                                margin-top: 10px;
                            }
                            .text5{
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #444444;
                                line-height: 16px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
@media screen and (min-width: 900px) {
	.quotation {
		height: 100%;
		.content {
			// margin-top:96px;
            .box1{
                width:100%;
                height: 1000px;
                .img1{
                    width:100%;
                    height:100%;
                }
            }
            .box-mob{
                display: none;
            }
            .box2{
                position: relative;
                z-index: 9;
                width: 1554px;
                height: 680px;
                background: #FFFFFF;
                box-shadow: 0px 10px 15px 0px rgba(4,0,0,0.1);
                border-radius: 30px;
                margin-top: -340px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .box2-left{
                    width: 680px;
                    height: 100%;
                    padding: 50px 140px 50px 43px;
                    box-sizing: border-box;
                    border-right: 1px solid #E5E5E5;
                    .text1{
                        
                        font-weight: bold;
                        font-size: 44px;
                        color: #18062F;
                    }
                    .text2{
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                        margin-top: 15px;
                    }
                    .text3{
                        
                        font-weight: 300;
                        font-size: 20px;
                        color: #B1B1B1;
                    }
                    .info{
                        margin-top: 32px;
                        width: 100%;
                        .cus-form{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            width: 100%;
                            .el-form-item{
                                margin-right: initial !important;
                                margin-bottom: initial !important;
                            }
                        }
                        .list-item{
                            width: 100%;
                            position: relative;
                            .left-label{
                                position: absolute;
                                z-index: 9;
                                height: 80px;
                                width: 160px;
                                padding-left: 10px ;
                                box-sizing: border-box;
                                left: 5px;
                                top: 5px;
                                background: rgb(255, 255, 255);
                                
                                font-weight: 300;
                                font-size: 30px ;
                                color: #444444 ;
                            }
                            .phone {
                                /deep/.el-input__inner {
                                    padding-left: 15px !important;
                                }
                            }
                            .input {
                                width: 100%;
                                margin-bottom: 17px;
                                .req {
                                    color: #e30c0c;
                                    height: 90px;
                                    line-height: 75px;
                                }
                                /deep/.el-input__inner {
                                    width: 100%;
                                    height: 90px;
                                    padding-left: 165px !important;
                                    
                                    font-weight: 300;
                                    font-size: 30px !important;
                                    color: #444444 !important;
                                    line-height: 90px !important;
                                }
                                /deep/.el-input__suffix {
                                    right: 25px;
                                    .m2 {
                                        
                                        font-weight: 300;
                                        color: #666666;
                                        line-height: 30px;
                                        height: 90px;
                                        line-height: 90px;
                                        font-size: 24px;
                                    }
                                }
                                /deep/.el-icon-arrow-down,/deep/.el-icon-arrow-up {
                                    font-size: 30px !important;
                                    line-height: 90px !important;
                                }
                            }
                            .el-cascader {
                                /deep/.el-input__suffix {
                                    right: 25px !important;
                                }
                            }
                        }

                        // .input{
                        //     width: 100%;
                        //     margin-bottom: 30px;
                        //     .req{
                        //         color: #E30C0C;
                        //         height: 80px;
                        //         line-height: 65px;
                        //     }
                        //     /deep/ .el-input{
                        //         width: 100%;
                        //         height: 80px;
                                
                        //         .el-input__inner{
                        //             width: 100%;
                        //             height: 80px;
                        //         }
                        //     }
                        //     /deep/ .el-input__inner{
                        //         width: 100%;
                        //         height: 80px;
                        //         font-size: 24px !important;
                        //     }
                            
                            
                        //     /deep/.el-input__suffix{
                        //         right: 20px;
                        //         .m2{
                        //             height: 80px;
                        //             line-height: 80px;
                        //             font-size: 24px;
                        //         }
                        //     }
                        //     /deep/.el-icon-arrow-down{
                        //         font-size: 24px !important;

                        //     }
                        // }
                        // .phone{
                        //     /deep/.el-input__inner{
                        //      padding-left: 15px !important;   
                        //     }
                        // }
                        // .el-cascader{
                        //     /deep/.el-input__suffix{
                        //         right: 27px !important;
                        //     }
                        // }
                        // .el-select{
                        //     width: 100%;
                        //     margin-bottom: 30px;
                        //     /deep/.el-input__inner{
                        //         height: 80px;
                        //         font-size: 24px !important;
                        //         // padding-left: 180px;
                        //     }
                        //     /deep/.el-input__suffix{
                        //         right: 27px !important;
                        //     }
                        //     /deep/.el-icon-arrow-up{
                        //         font-size: 24px !important;
                        //     }
                        //     /deep/ .el-input__prefix{
                        //         left: 23px;
                        //         line-height: 80px;
                        //     }
                        //     // .prefix{
                        //     //     font-size: 30px !important;
                        //     // }
                        // }
                        
                    }
                }
                .box2-right{
                    // width: 60%;
                    flex: 1;
                    padding: 110px 60px 110px  200px;
                    box-sizing: border-box;
                    height: 100%;
                    .right-title{
                        .text2{
                            
                            font-weight: bold;
                            font-size: 44px;
                            color: #18062F;
                        }
                        .text5{
                            
                            font-weight: bold;
                            font-size: 44px;
                            color: #753CBE;
                        }
                    }
                    .list{
                        margin-top: 40px;
                        width: 100%;
                        .list-item{
                            .icon{
                                width: 161px;
                                height: 161px;
                                border-radius: 20px;
                                border: 1px solid #DFDDE1;
                                .img1{
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                            .text3{
                                margin-top: 10px;
                                
                                font-weight: bold;
                                font-size: 30px;
                                color: #18062F;
                            }
                            .text4{
                                margin-top: 2px;
                                
                                font-weight: bold;
                                font-size: 30px;
                                color: #18062F;
                            }
                        }
                    }
                    .text4{
                        
                        font-weight: 400;
                        font-size: 24px;
                        color: #18062F;
                        line-height: 40px;
                        margin-top: 60px;
                        text-align: center;
                    }
                    
                }
                .btn{
                    cursor: pointer;
                    position: absolute;
                    left: 561px;
                    top: 227px;
                    width: 231px;
                    height: 233px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    border: 1px solid #DFDDE1;
                    .q3{
                        z-index: 9;
                        width: 160px;
                        height: 160px;
                        background: #FD8B2F;
                        border-radius: 50%;
                        span{
                            
                            font-weight: bold;
                            font-size: 36px;
                            height: 43px;
                            line-height: 43px;
                            color: #F6EEFF;
                        }
                    }
                    .q2 {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 160px;
                        height: 160px;
                        background-color: rgba(253, 139, 47, 0.4);
                        border-radius: 50%;
                        transform: translate(-50%, -50%) scale(1);
                        opacity: 1;
                        pointer-events: none;
                        animation: ripple-animation 0.9s infinite ;
                    }
                    @keyframes ripple-animation {
                        0% {
                            transform: translate(-50%, -50%) scale(1);
                            opacity: 1;
                        }
                        // 20%{
                        //     transform: translate(-50%, -50%) scale(1.05);
                        //     opacity: 0.8;
                        // }
                        40%{
                            transform: translate(-50%, -50%) scale(1.15);
                            opacity: 0.6;
                        }
                        60%{
                            transform: translate(-50%, -50%) scale(1.2);
                            opacity: 0.4;
                        }

                        80%{
                            transform: translate(-50%, -50%) scale(1.25);
                            opacity: 0.2;
                        }
                        
                        100% {
                            transform: translate(-50%, -50%) scale(1.3);
                            opacity: 0;
                        }
                        
                        // 95%, 100% {
                        //     transform: translate(-50%, -50%) scale(1);
                        //     opacity: 0;
                        // }
                    }
                }
            }
            .box3{
                padding: 117px 184px;
                background: #fff;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 72px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 25px;
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                    }
                }
                .list,.bottom{
                    margin-top: 55px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    .item1{
                        background: url(../image/price/box3_bg1.jpg);
                    }
                    .item2{
                        background: url(../image/price/box3_bg2.jpg);
                    }
                    .item3{
                        background: url(../image/price/box3_bg3.jpg);
                    }
                    .item4{
                        background: url(../image/price/box3_bg4.jpg);
                    }
                    
                    .list-item{
                        width: 757px;
                        height: 430px;
                        border-radius: 15px;
                        overflow: hidden;
                        position: relative;
                        
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        padding: 50px 74px;
                        box-sizing: border-box;
                        margin-bottom: 40px;
                        .mask{
                            border-radius: 15px 30px 15px 15px;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background: rgba(117, 60, 190, 0.9);
                        }
                        .text3{
                            z-index: 9;
                            
                            font-weight: bold;
                            font-size: 60px;
                            color: #FFFFFF;
                        }
                        .cus-line{
                            z-index: 9;
                            width: 120px;
                            height: 10px;
                            background: #FFFFFF;
                            margin-top: 30px;
                        }
                        .text4{
                            z-index: 9;
                            
                            font-weight: 400;
                            font-size: 36px;
                            color: #FFFFFF;
                            line-height: 48px; 
                            margin-top: 30px;
                        }
                        .index{
                            position: absolute;
                            right: -150px;
                            top: -150px;
                            background: #F8F8F9;
                            width: 320px;
                            height: 290px;
                            border-radius: 50%;
                            font-family: DIN;
                            font-weight: bold;
                            font-size: 90px;
                            color: #753CBE;
                            line-height: 90px;
                            padding-left:50px ;
                            padding-bottom: 50px;
                            box-sizing: border-box;
                        }
                    }
                    
                }
                .bottom{
                    width: 100%;
                    height: 640px;
                    border-radius: 15px;
                    padding: 105px 0 105px 74px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    box-sizing: border-box;
                    background: url(../image/price/box3_bg5.jpg);
                    .list-item{
                    }
                }
            }
            .box4{
                background: #fff;
                width: 100%;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 72px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 25px;
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                    }
                }
                .box4-content{
                    margin-top: 50px;
                    height: 960px;
                    width: 100%;
                    background: url(../image/price/box4_bg1.jpg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    padding: 0 180px;
                    box-sizing: border-box;
                    .list{
                        width: 100%;
                        .list-item{
                            width: 490px;
                            height: 533px;
                            background: #FFFFFF;
                            border-radius: 30px;
                            .img2{
                                height: 324px;
                                width: 100%;

                            }
                            .text3{
                                
                                font-weight: bold;
                                font-size: 40px;
                                color: #120523;
                                margin-top: 34px;
                                padding: 0 66px;

                            }
                            .text4{
                                margin-top: 10px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #444444;
                                line-height: 42px;
                                padding: 0 66px;
                                text-align: center;
                            }
                        }
                    }
                    .btn{
                        width: 613px;
                        height: 151px;
                        background: #753CBE;
                        border-radius: 76px;
                        
                        font-weight: bold;
                        font-size: 54px;
                        color: #FFFFFF;
                        margin-top: 60px;
                        cursor: pointer;
                    }
                }
            }
            .box5{
                background: #fff;
                width: 100%;
                margin-top: 110px;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 72px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 25px;
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                    }
                }
                .box-content{
                    cursor: pointer;
                    display: grid;
                    width: 100%;
                    margin-top: 50px;
                    padding: 0 183px;
                    box-sizing: border-box;
                    // grid-template-columns: 50% 25% 25%;
                    grid-gap: 40px;
                    // grid-template-rows: 200px 100px;
                    grid-template-columns: repeat(3, 1fr);
                    grid-auto-flow: row;
                    .item0{
                        grid-row: 1 / span 2;
                        width: 714px !important;
                        height: 880px !important;
                        .info{
                            align-content: flex-start;
                            height: 306px !important;
                            padding:30px !important;
                        }
                        .head{
                            display: flex;
                            align-items: flex-end !important;
                            justify-content: flex-start !important;
                            .text4{
                                line-height: 60px !important;
                                font-size: 60px !important;
                            }
                        }
                    }
                    .item3{
                        width: 200px;

                    }
                    .list-item:hover{
                        .list-img{
                            transform: scale(1.2)
                        }
                    }
                    .list-item{
                        position: relative;
                        width: 380px;
                        height: 420px;
                        overflow: hidden;
                        .list-img{
                            transition: all .6s;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            object-fit: cover;
                        }
                        .info{
                            height: 86px;
                            position: relative;
                            padding:30px ;
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            align-content: center;
                            width: 100%;
                            box-sizing: border-box;
                            .mask{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: rgba(0,0,0,0.5);
                            }
                            .head{
                                z-index: 9;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .text4{
                                    
                                    font-weight: 500;
                                   
                                    color: #FFFFFF;
                                    margin-right: 18px;
                                    font-size: 44px;
                                    line-height: 44px;
                                }
                                .text5{
                                    
                                    font-weight: 400;
                                    font-size: 30px;
                                    color: #FFFFFF;
                                }
                            }
                            .text1{
                                z-index: 9;
                                margin-top: 32px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #FFFFFF;
                            }
                            .info-box{
                                z-index: 9;
                                margin-top: 30px;
                                .text2{
                                    width: 213px;
                                    
                                    font-weight: 400;
                                    font-size: 30px;
                                    color: #FFFFFF;
                                }
                                .text3{
                                    
                                    font-weight: 400;
                                    font-size: 30px;
                                    color: #FFFFFF;
                                }
                            }
                            .btn{
                                z-index: 9;
                                // cursor: pointer;
                                position: absolute;
                                top: 37px;
                                right: 0;
                                width: 210px;
                                height: 64px;
                                background: #753CBE;
                                border-radius: 32px 0px 0px 32px;
                                
                                font-weight: 400;
                                font-size: 36px;
                                color: #FFFFFF;
                            }
                       }
                    }
                }
                .row2{
                    width: 100%;
                    margin-top: 36px;
                    padding: 0 183px;
                    box-sizing: border-box;
                    display: grid;
                    justify-content: flex-start;
                    grid-template-columns: repeat(6, 1fr);
                    grid-gap: 35px;
                    .des-item:hover{
                        .img{
                            transform: scale(1.2);
                        }
                        
                    }
                    .des-item{
                        cursor: pointer;
                        width: 230px;
                        height: 280px;
                        overflow: hidden;
                        .img{
                            transition: all .6s;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .box6{
                background: #fff;
                width: 100%;
                margin-top: 110px;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 72px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 25px;
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                    }
                }
                .box6-content{
                    width: 100%;
                    margin-top: 40px;
                    .tab-list{
                        width: 100%;
                        padding: 0 290px;
                        box-sizing: border-box;
                        .list-item{
                            width: 171px;
                            height: 171px;
                            background: #FFFFFF;
                            box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
                            border-radius: 50%;
                            overflow: hidden;
                            cursor: pointer;
                            box-sizing: border-box;
                            // border: 3px solid #7840BF;
                            .img3{
                                width: 120px;
                                height: 90px;
                            }
                        }
                        .tab-active{
                            border: 3px solid #7840BF;
                        }
                    }
                    .banner{
                        margin-top: 36px;
                        width: 100%;
                        height: 1100px;
                        position: relative;
                        .img4{
                            width: 100%;
                            height: 100%;
                        }
                        .info{
                            position: absolute;
                            top: 245px;
                            right: 220px;
                            width: 760px;
                            height: 610px;
                            background: #FFFFFF;
                            border-radius: 19px;
                            opacity: 0.98;
                            padding: 90px;
                            box-sizing: border-box;
                            .text3{
                                
                                font-weight: bold;
                                font-size: 64px;
                                color: #120523;
                                line-height: 64px;
                            }
                            .text4{
                                
                                font-weight: bold;
                                font-size: 48px;
                                color: #120523;
                                line-height: 55px;
                                margin-top: 33px;
                            }
                            .text5{
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #444444;
                                line-height: 30px;
                                margin-top: 39px;
                                line-height: 48px;
                            }
                        }
                    }
                }
            }
		}
	}
    
}
</style>
